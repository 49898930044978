$color-blue: #3c4af1;
$color-black: #121c4c;
$color-white: #ffffff;
$color-yellow: #faef95;
$color-white-10: rgba(255, 255, 255, 0.1);
$color-white-20: rgba(255, 255, 255, 0.2);
$color-white-40: rgba(255, 255, 255, 0.4);
$color-white-50: rgba(255, 255, 255, 0.5);
$color-light-green: rgba(187, 250, 149, 1);
$color-gray: #9d9bc7;
$color-black-60: rgba(0, 0, 0, 0.6);
$color-rose: #e3206a;
$color-rose-20: rgba(227, 32, 106, 0.2);
$color-black-100: rgba(0, 0, 0, 1);
$color-black-20: rgba(0, 0, 0, 0.2);

$font-primary: 'Nunito', sans-serif;

$yellow-shadow: 0px 3px 20px 0px rgba(253, 230, 108, 0.8);
$yellow-text-shadow: 0px 0px 12px rgba(253, 230, 108, 0.8);

:export {
  colorBlue: $color-blue;
  colorBlack: $color-black;
  colorWhite: $color-white;
  colorYellow: $color-yellow;
  colorWhite10: $color-white-10;
  colorWhite20: $color-white-20;
  colorWhite40: $color-white-40;
  colorWhite50: $color-white-50;
  colorLightGreen: $color-light-green;
  colorGray: $color-gray;
  fontPrimary: $font-primary;
  yellowShadow: $yellow-shadow;
  yellowTextShadow: $yellow-text-shadow;
  colorBlack60: $color-black-60;
  colorRose: $color-rose;
  colorRose20: $color-rose-20;
}

@mixin common-button-styles {
  border: none;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin common-input-styles {
  font-size: 14px;
  font-weight: 400;
  font-family: $font-primary;
  line-height: 16.8px;
  color: $color-white;
  border-radius: 20px;
  outline: none;
  border: none;
  padding: 20px;
  width: 100%;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  @include min-w-391 {
    font-size: 16px;
    line-height: 19.2px;
  }
}

@mixin block-user-interaction {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  touch-action: none;
  -webkit-touch-callout: none;
}

@mixin common-typography-styles {
  font-family: $font-primary;
  user-select: none;
}

@mixin hide-scroll {
  &::-webkit-scrollbar {
    display: none;
  }

  & {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
}

@mixin unselectable {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

@mixin min-w-375 {
  @media (min-width: 375px) {
    @content;
  }
}

@mixin min-w-391 {
  @media (min-width: 391px) {
    @content;
  }
}

@mixin min-w-500 {
  @media (min-width: 500px) {
    @content;
  }
}
@mixin min-w-700 {
  @media (min-width: 700px) {
    @content;
  }
}

@mixin min-h-723 {
  @media (min-height: 723px) {
    @content;
  }
}

.root {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  min-height: 100%;
}

.container {
  flex: 1;
  min-height: 100%;
  display: flex;
  flex-direction: column;

  @include min-h-723 {
    justify-content: space-around;
  }
}

.boostersRoot {
  width: 100%;
  text-align: center;

  .overflowContainer {
    @include hide-scroll;

    width: 100%;
    height: 95px;
    overflow-x: auto;
    margin-bottom: 16px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
  }

  .boostersContainer {
    display: flex;
    align-items: center;
    gap: 12px;
    padding-right: 17px;
    min-width: fit-content;
    width: 100%;
  }

  .booster {
    position: relative;
    min-width: 80px;
    height: 80px;
    background-color: $color-white-10;
    padding: 12px 16px;
    border-radius: 20px;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  .boosterImage {
    display: block;
    position: absolute;
    top: -15px;
  }

  .boostersComignSoon {
    @include min-w-500 {
      transform: scale(1.3);
    }

    @include min-w-700 {
      transform: scale(1.5);
    }
  }
}

.userSizeDonutRoot {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  text-align: center;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-right: 17px;
  margin: auto 0;
  position: relative;
  justify-content: center;


  .starsContainer {
    position: absolute;
    width: 100%;
    max-width: 336px;
    pointer-events: none;
  }

  @include min-w-500 {
    transform: scale(1.3);
  }

  @include min-w-700 {
    transform: scale(1.5);
  }
}

.blue-donut-container {
  @include unselectable;

  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  position: relative;

  contain: paint;
  transform: translateZ(0);

  .blue-donut-outer-ring {
    width: 160px;
    height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid $color-white-20;
    background-color: $color-white-10;
    border-radius: 50%;
    aspect-ratio: 1/1;
    z-index: 2;
    will-change: transform;
    transform: translate3d(0, 0, 0);
    
    &.pulse {
        animation: pulse 2s ease-in-out infinite;
    }    
  }

  @keyframes pulse {
    0%, 100% {
      transform: scale3d(1, 1, 1);
    }
    50% {
      transform: scale3d(0.96, 0.96, 1);
    }
  }

  .blue-donut-inner-ring {
    width: 146px;
    height: 146px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid $color-white-20;
    background-color: $color-white-10;
    border-radius: 50%;
    position: relative;
  }

  .blue-donut {
    box-shadow: $yellow-shadow;
    border-radius: 50%;
  }

  .donutCenter {
    position: absolute;
    box-shadow: inset $yellow-shadow;
    border-radius: 50%;
  }
}

.session-timer {
  width: 100%;
  max-width: 356px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  flex-direction: column;
  text-align: center;
  margin-bottom: 20px;
  padding-right: 17px;

  @include min-w-500 {
    transform: scale(1.3);
  }

  @include min-w-700 {
    transform: scale(1.5);
  }

  .digitSlotsContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    width: 100%;
    max-width: 356px;
    margin: 0 auto;
  }

  .digit-slot-pair {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    max-width: 96px;
    width: 100%;
  }

  .digit-slot {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 46px;
    width: 100%;
    height: 58px;
    background-color: $color-white-20;
    border-radius: 16px;
    flex: 1;
  }
}

.limitRoot {
  display: flex;
  gap: 10px;
  align-items: center;
  background-color: $color-white-10;
  padding: 17px 16px;
  border-radius: 20px;
  margin-right: 17px;
  overflow: hidden;
  word-wrap: break-all;
  position: relative;

  @include min-w-700 {
    padding: 24px;
    gap: 40px;
  }

  .showAdsButton {
    position: absolute;
    right: 16px;
    top: 16px;
  }

  .limitContainer {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: flex-start;

    @include min-w-700 {
      transform: scale(1.2);
    }
  }

  .limitIcon {
    display: block;
    @include min-w-700 {
      transform: scale(1.5);
    }
  }

  .limit {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .arrowRight {
    transform: rotate(-90deg);
    width: 10px;
    height: 10px;
    color: $color-white;
  }
}


.turboBtn {
  @include common-button-styles;
  color: white;
  font-size: 12px;
  font-weight: 600;
  padding-top: 4px;
  padding-bottom: 8px;
  padding-right: 8px;
  padding-left: 4px;
  gap: 2px;
  line-height: 1;
  background-color: transparent;
  background-size: cover;
  background-repeat: no-repeat;
}

.animateFromTop {
    animation: exitToTop 0.3s ease-out forwards;
}

.animateFromBottom {
    animation: exitToBottom 0.3s ease-out forwards;
}

@keyframes exitToTop {
    to {
        transform: translateY(-1000%);
        opacity: 0;
    }
}

@keyframes exitToBottom {
    to {
        transform: translateY(1000%);
        opacity: 0;
    }
}