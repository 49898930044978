* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
}

body {
  overflow: hidden;
  /* transform: translateZ(0); */
}

#root {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  appearance: textfield;
  -moz-appearance: textfield;
}

a {
  text-decoration: none;
  color: inherit;
  font-family: inherit;
}
img {
  user-select: none;
  pointer-events: none;
}
