$color-blue: #3c4af1;
$color-black: #121c4c;
$color-white: #ffffff;
$color-yellow: #faef95;
$color-white-10: rgba(255, 255, 255, 0.1);
$color-white-20: rgba(255, 255, 255, 0.2);
$color-white-40: rgba(255, 255, 255, 0.4);
$color-white-50: rgba(255, 255, 255, 0.5);
$color-light-green: rgba(187, 250, 149, 1);
$color-gray: #9d9bc7;
$color-black-60: rgba(0, 0, 0, 0.6);
$color-rose: #e3206a;
$color-rose-20: rgba(227, 32, 106, 0.2);
$color-black-100: rgba(0, 0, 0, 1);
$color-black-20: rgba(0, 0, 0, 0.2);

$font-primary: 'Nunito', sans-serif;

$yellow-shadow: 0px 3px 20px 0px rgba(253, 230, 108, 0.8);
$yellow-text-shadow: 0px 0px 12px rgba(253, 230, 108, 0.8);

:export {
  colorBlue: $color-blue;
  colorBlack: $color-black;
  colorWhite: $color-white;
  colorYellow: $color-yellow;
  colorWhite10: $color-white-10;
  colorWhite20: $color-white-20;
  colorWhite40: $color-white-40;
  colorWhite50: $color-white-50;
  colorLightGreen: $color-light-green;
  colorGray: $color-gray;
  fontPrimary: $font-primary;
  yellowShadow: $yellow-shadow;
  yellowTextShadow: $yellow-text-shadow;
  colorBlack60: $color-black-60;
  colorRose: $color-rose;
  colorRose20: $color-rose-20;
}

@mixin common-button-styles {
  border: none;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin common-input-styles {
  font-size: 14px;
  font-weight: 400;
  font-family: $font-primary;
  line-height: 16.8px;
  color: $color-white;
  border-radius: 20px;
  outline: none;
  border: none;
  padding: 20px;
  width: 100%;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  @include min-w-391 {
    font-size: 16px;
    line-height: 19.2px;
  }
}

@mixin block-user-interaction {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  touch-action: none;
  -webkit-touch-callout: none;
}

@mixin common-typography-styles {
  font-family: $font-primary;
  user-select: none;
}

@mixin hide-scroll {
  &::-webkit-scrollbar {
    display: none;
  }

  & {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
}

@mixin unselectable {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

@mixin min-w-375 {
  @media (min-width: 375px) {
    @content;
  }
}

@mixin min-w-391 {
  @media (min-width: 391px) {
    @content;
  }
}

@mixin min-w-500 {
  @media (min-width: 500px) {
    @content;
  }
}
@mixin min-w-700 {
  @media (min-width: 700px) {
    @content;
  }
}

@mixin min-h-723 {
  @media (min-height: 723px) {
    @content;
  }
}

.donut-container {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    position: absolute;
    z-index: 2;
    transform: translateY(-140px);
    will-change: transform;

    animation: fall linear forwards;
    // animation: fall var(--animation-duration) linear forwards;
    // animation: fall var(--animation-duration) linear var(--start-delay) forwards;

    @include unselectable;
}

@keyframes fall {
    from {
        transform: translate3d(var(--pos-x), -140px, 0);
    }
    to {
        transform: translate3d(var(--pos-x), var(--donut-fall-distance), 0);
    }
}

.donutOuterContainer {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.donut-inner-container {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    box-shadow: $yellow-shadow;
}

.donut {
    position: absolute;
    width: 120px;
    height: 120px;
    border-radius: 50%;

    // image-rendering: pixelated;
}

.award {
    position: absolute;
    border-radius: 50%;
    border: none;
    outline: none;
    opacity: 0;
    transform: scale(0.7);
    text-shadow: $yellow-text-shadow;

    animation: award-animation 0.3s linear;

    @include block-user-interaction;
}

.stars {
    position: absolute;
    width: 142px;
    height: 142px;
    pointer-events: none;
    z-index: 3;
}

.donutCenter {
    box-shadow: inset $yellow-shadow;
    border-radius: 50%;
}

.rotateLeft {
    animation: rotate-left 0.55s linear forwards;
}

.rotateRight {
    animation: rotate-right 0.55s linear forwards;
}

.jumpedLeft {
    animation: jump-down-left 0.55s linear forwards;
}

.jumpedRight {
    animation: jump-down-right 0.55s linear forwards;
}

@keyframes award-animation {
    0% {
        opacity: 0;
        transform: scale(0.7);
    }

    50% {
        opacity: 1;
    }

    to {
        opacity: 0;
        transform: scale(1.3) translateY(-100px);
    }
}

@keyframes rotate-left {
    from {
        rotate: 0;
    }

    to {
        rotate: -360deg;
    }
}

@keyframes rotate-right {
    from {
        rotate: 0;
    }

    to {
        rotate: 360deg;
    }
}

@keyframes jump-down-right {
    0% {
        transform: translate(0, 0);
    }

    10% {
        transform: translate(10px, -15px);
    }

    27% {
        transform: translate(30px, 0px);
    }

    100% {
        transform: translate(30px, 100vh);
    }
}

@keyframes jump-down-left {
    0% {
        transform: translate(0, 0);
    }

    10% {
        transform: translate(-10px, -15px);
    }

    27% {
        transform: translate(-30px, 0px);
    }

    100% {
        transform: translate(-30px, 100vh);
    }
}